import { Alert, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
  const navigate = useNavigate();

  return (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      <Alert
        variant="info"
        style={{ textAlign: "center", fontSize: 45, margin: 30 }}
      >
        {"Page Not Found"}
      </Alert>
      <Button variant="primary" onClick={() => navigate("/")}>
        {"Go back to Home Page"}
      </Button>
    </div>
  );
}
