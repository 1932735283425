import { useContext, useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { LaunchDarklyContext } from "../../context/LaunchDarklyContext";
import "../../css/App.css";
import "../../css/EnvironmentsStyle.css";
import { UpdateCurrentFeatureFlag } from "../../data/featureFlagsHelpers";
import { removeARule } from "../../data/httpCalls";
import AddRuleForm from "./forms/AddRuleForm";

export default function FeatureFlags() {
  const {
    currentEnvironment,
    setCurrentFeatureFlag,
    currentFeatureFlag,
    setCurrentFeatureFlagsList,
    recentlyViewList,
    setRecentlyViewList,
  } = useContext(LaunchDarklyContext);
  const [addRuleClicked, setAddRuleClicked] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleAddRuleClick = () => {
    setAddRuleClicked(!addRuleClicked);
  };

  // ONLY FOR TESTING PURPOSES
  const handleRemoveRule = async (ruleId) => {
    //TODO: Add a popup modal to confirm the remove action
    try {
      await removeARule(currentEnvironment.key, currentFeatureFlag.key, ruleId);
      // update
      await UpdateCurrentFeatureFlag(
        recentlyViewList,
        setRecentlyViewList,
        currentEnvironment,
        currentFeatureFlag,
        setCurrentFeatureFlag,
        setCurrentFeatureFlagsList
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="mainBody">
      <p className="mainHeader">{currentFeatureFlag?.name}</p>
      <p className="flagDescription">{currentFeatureFlag?.description}</p>
      <Button onClick={handleAddRuleClick} variant="info">
        {addRuleClicked ? "Cancel Adding Rule" : "Add A New Rule"}
      </Button>
      {addRuleClicked && <AddRuleForm setAddRuleClicked={setAddRuleClicked} />}

      {currentFeatureFlag?.rules.length > 0 ? (
        currentFeatureFlag?.rules.map((rule, ruleIndex) => (
          <Card key={ruleIndex} className="mainCard">
            <Card.Header>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="clickable">{rule.description}</div>
                <div className="stackStatus">{rule.ruleStatus}</div>
              </div>
            </Card.Header>
            <Card.Body>
              {rule.clauses.map((clause, clauseIndex) => (
                <div className="rule" key={clauseIndex}>
                  <h5>{clause.attribute}: </h5>
                  {clause.values.map((value, valueIndex) => (
                    <div key={valueIndex}>{value}</div>
                  ))}
                </div>
              ))}
              {rule.ruleId && (
                <footer className="blockquote-footer">
                  Rule ID: {rule.ruleId}
                </footer>
              )}
            </Card.Body>

            {/* DELETE BUTTON IS JUST FOR TESTING PURPOSES */}
            {/* TODO: COMMENT WHEN PUSH */}
            {/* <Card.Footer>
              <Button
                onClick={() => handleRemoveRule(rule.ruleId)}
                variant="danger"
              >
                Remove Rule
              </Button>
            </Card.Footer> */}
          </Card>
        ))
      ) : (
        <div className="noRule">
          {!addRuleClicked && "No rules for this feature flag yet!"}
        </div>
      )}
    </div>
  );
}
