import { createContext, useState } from "react";

//** LaunchDarklyContext provides a list of global states
//** using across all components to reduce API calls. */

export const LaunchDarklyContext = createContext();

export const LaunchDarklyProvider = ({ children }) => {
  // List of environments' keys & name
  const [environmentsList, setEnvironmentsList] = useState([]);
  // List of current environment's feature flags
  const [currentFeatureFlagsList, setCurrentFeatureFlagsList] = useState([]);

  // Current environment's key & name
  const [currentEnvironment, setCurrentEnvironment] = useState(null);
  // Current feature flag's details (key, name, fallthrough, rules, etc.)
  const [currentFeatureFlag, setCurrentFeatureFlag] = useState(null);

  // Access token
  const [accessToken, setAccessToken] = useState(null);

  return (
    <LaunchDarklyContext.Provider
      value={{
        environmentsList,
        setEnvironmentsList,
        currentFeatureFlagsList,
        setCurrentFeatureFlagsList,
        currentEnvironment,
        setCurrentEnvironment,
        currentFeatureFlag,
        setCurrentFeatureFlag,
        accessToken,
        setAccessToken,
      }}
    >
      {children}
    </LaunchDarklyContext.Provider>
  );
};
