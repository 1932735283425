import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Navigate, Outlet } from "react-router-dom";
import { loginRequest } from "../auth-config";
import { LaunchDarklyContext } from "../context/LaunchDarklyContext";
import "../css/EnvironmentsStyle.css";

export default function PrivateRoute() {
  const { instance, accounts, inProgress } = useMsal();
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const { accessToken, setAccessToken } = useContext(LaunchDarklyContext);

  // Only call "loginRedirect" if there's no active account
  // and when checking if user is authenticated
  useEffect(() => {
    const activeAccount = instance.getActiveAccount();
    if (!activeAccount) {
      instance
        .loginRedirect({
          ...loginRequest,
          prompt: "create",
        })
        .catch((error) => console.log(error));
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (inProgress === "none") {
      checkAuthentication();
    }

    if (accessToken && isTokenExpired(accessToken)) {
      instance.logoutRedirect().catch((error) => {
        console.error("Logout failed", error);
      });
    }
  }, [accounts, inProgress]);

  const checkAuthentication = async () => {
    const activeAccount = instance.getActiveAccount();
    if (activeAccount) {
      setIsAuthenticated(true);
      await acquireToken();
    } else {
      try {
        const response = await instance.ssoSilent({
          scopes: ["User.Read"],
          loginHint: accounts[0] && accounts[0].username,
        });
        instance.setActiveAccount(response.account);
        setIsAuthenticated(true);
        await acquireToken();
      } catch (error) {
        console.error("SSO Silent failed", error);
        setIsAuthenticated(false);
      }
    }
  };

  /** Acquire an access token silently or interactively for the authenticated user */
  const acquireToken = async () => {
    try {
      const accessTokenResponse = await instance.acquireTokenSilent(
        loginRequest
      );
      const accessToken = accessTokenResponse.accessToken;
      //console.log("AccessToken: {0}", accessToken);
      setAccessToken(accessToken);
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        try {
          const accessTokenResponse = await instance.acquireTokenPopup(
            loginRequest
          );
          const accessToken = accessTokenResponse.accessToken;
          // console.log("Access Token {0}", accessToken);
          setAccessToken(accessToken);
        } catch (popupError) {
          console.error("Acquire token interactive failure", popupError);
        }
      } else {
        console.error(error);
      }
    }
  };

  /** Check if the token is expired */
  const isTokenExpired = (token) => {
    if (!token) return true;
    const decodedToken = JSON.parse(atob(token.split(".")[1]));
    const expiration = decodedToken.exp * 1000;
    return expiration < Date.now();
  };

  if (isAuthenticated === null) {
    return (
      <div className="loadingSpinner">
        <Spinner animation="border" role="status"></Spinner>
        <div> Please wait, we're verifying your login... </div>
      </div>
    );
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
}