import { Autocomplete, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Alert, Button, Container, Modal, Nav, Navbar } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { LaunchDarklyContext } from "../context/LaunchDarklyContext";
import "../css/App.css";
import { fetchEnvironments } from "../data/httpCalls";

export default function Header() {
  const navigate = useNavigate();
  const { environmentKey } = useParams(); // get environmentKey directly from URL
  const {
    environmentsList,
    setEnvironmentsList,
    currentEnvironment,
    setCurrentEnvironment,
    accessToken,
  } = useContext(LaunchDarklyContext);

  // states for retry
  const [retryTimeout, setRetryTimeout] = useState(0);
  const [showRetryModal, setShowRetryModal] = useState(false);

  // Update current environment when route change
  useEffect(() => {
    if (accessToken !== null) {
      getEnvironments();
    }
  }, [environmentKey, accessToken]);

  // when currentEnvironment changes, change route
  useEffect(() => {
    if (currentEnvironment) {
      navigate(`/${currentEnvironment?.key}`);
    }
  }, [currentEnvironment]);

  // Fetch all environments
  const getEnvironments = async () => {
    try {
      setEnvironmentsList([]); // delete old data
      const envData = await fetchEnvironments(accessToken);

      if (envData && envData.data.error === null) {
        setEnvironmentsList(envData.data.items);
        if (!currentEnvironment && envData.data.items.length > 0) {
          setCurrentEnvironment(envData.data.items[0]);
        }
      }
      // rate limit exceeded
      else if (envData.data.error && envData.data.error.code === "429") {
        console.log({ envData });
        setEnvironmentsList([]); // outdated when couldn't fetch
        setRetryTimeout(envData.data.error.timeout / 1000); // set and convert ms to seconds
        setShowRetryModal(true);
        const countdownInterval = setInterval(() => {
          setRetryTimeout((prev) => {
            if (prev <= 0) {
              clearInterval(countdownInterval);
              return 0;
            }
            return prev - 1;
          });
        }, 1000);
      }
    } catch (err) {
      console.error("Failed to fetch environments: ", err);
    }
  };

  return (
    <>
      <Navbar expand="lg" bg="light" className="bg-body-tertiary">
        <Container fluid>
          <Navbar.Brand href="/" className="appName">
            {"LaunchDarklyClients"}
          </Navbar.Brand>
          <Nav className="ms-auto d-flex align-items-center">
            {environmentsList?.length > 0 ? (
              <Autocomplete
                disablePortal
                id="combo-box"
                options={environmentsList}
                getOptionLabel={(option) => option.name}
                sx={{ width: 260 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`BrinkPOS - ${currentEnvironment?.name}`}
                  />
                )}
                onChange={(event, value) => {
                  if (value) {
                    setCurrentEnvironment(value);
                  }
                }}
              />
            ) : (
              <Alert variant="info">{"Getting environments..."}</Alert>
            )}
          </Nav>
        </Container>
      </Navbar>

      {/* Modal for retry */}
      <Modal
        show={showRetryModal}
        backdrop="static"
        onHide={() => setShowRetryModal(false)}
      >
        <Modal.Header>
          <Modal.Title>Rate Limit Exceeded</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {`Rate limit exceeded. Please retrying in ${retryTimeout} seconds...`}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="warning"
            onClick={() => {
              getEnvironments();
              setShowRetryModal(false);
            }}
            disabled={retryTimeout > 0}
          >
            {"Retry Now"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
