// Fetch all available environments
export const fetchEnvironments = async (accessToken) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_LAUNCH_DARKLY_URL}/Environments`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Network response failed: ", response.statusText);
    }
    return await response.json();
  } catch (error) {
    console.error("Fetch environments failed: ", error);
  }
};

// Fetch all Feature Flags of an Environment
export const fetchFeatureFlags = async (
  accessToken,
  environmentKey,
  featureFlagKey = null
) => {
  try {
    const url = new URL(
      `${process.env.REACT_APP_LAUNCH_DARKLY_URL}/FeatureFlags?environmentKey=${environmentKey}`
    );
    if (featureFlagKey !== null) {
      url.searchParams.append("featureFlagKey", featureFlagKey);
    }

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Network response is not ok: ", response.statusText);
    }
    return await response.json();
  } catch (error) {
    console.error("Fetch Feature Flag failed: ", error);
  }
};

// Add a new rule
export const addANewRule = async (
  accessToken,
  environmentKey,
  featureFlagKey,
  newRuleInstruction
) => {
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(newRuleInstruction),
    };

    const response = await fetch(
      `${process.env.REACT_APP_LAUNCH_DARKLY_URL}/FeatureFlags/addRule?environmentKey=${environmentKey}&featureFlagKey=${featureFlagKey}`,
      requestOptions
    );

    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(
        `Failed to add a new rule: ${responseData.statusCode} - ${responseData.errors[0].message}`
      );
    }

    return responseData;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getRoSFlags = async (accessToken) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_LAUNCH_DARKLY_URL}/FeatureFlags/RoSFlags`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};

// Toggle RoS ON: turn ON/OFF 12 RoS-related Feature Flags
export const toggleRoS = async (
  accessToken,
  environmentKey,
  toggleRoSRequest,
  setSuccessMessage,
  setErrorMessage
) => {
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(toggleRoSRequest),
    };

    const response = await fetch(
      `${process.env.REACT_APP_LAUNCH_DARKLY_URL}/FeatureFlags/toggleRoS?environmentKey=${environmentKey}`,
      requestOptions
    );

    const dataResponse = [];

    if (response.body) {
      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
      let done = false;
      let buffer = "";

      while (!done) {
        const { value, done: readerDone } = await reader.read();
        done = readerDone;

        // reset messages for a new batch
        setSuccessMessage(null);
        setErrorMessage(null);

        if (value) {
          buffer += decoder.decode(value, { stream: true });

          let newlineIndex;
          while ((newlineIndex = buffer.indexOf("\n")) >= 0) {
            const chunk = buffer.slice(0, newlineIndex);
            buffer = buffer.slice(newlineIndex + 1);

            try {
              const parsedChunk = JSON.parse(chunk);
              dataResponse.push(parsedChunk);

              // Update messages along the way
              if (parsedChunk.status !== 0) {
                setErrorMessage((prevMessage) =>
                  prevMessage
                    ? `${prevMessage}\n${parsedChunk.message}`
                    : parsedChunk.message
                );
              } else {
                setSuccessMessage((prevMessage) =>
                  prevMessage
                    ? `${prevMessage}\n${parsedChunk.message}`
                    : parsedChunk.message
                );
              }
            } catch (error) {
              console.error("Error parsing JSON chunk: ", error);
            }
          }
        }
      }
    }

    return dataResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// Remove a rule (Just for testing right now)
export const removeARule = async (
  accessToken,
  environmentKey,
  featureFlagKey,
  ruleId
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_LAUNCH_DARKLY_URL}/FeatureFlags/removeRule?environmentKey=${environmentKey}&featureFlagKey=${featureFlagKey}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(ruleId),
      }
    );

    if (response.ok) {
      const data = await response.json();
      console.log("Rule removed successfully: ", data.message);
    } else {
      const errorData = await response.json();
      console.error("Failed to remove rule:", errorData);
    }
  } catch (error) {
    console.error(error);
  }
};
