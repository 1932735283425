import { useContext, useEffect, useState } from "react";
import { Button, Card, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { filterFeatureFlagsInfo } from "../../data/featureFlagsHelpers";
import { fetchFeatureFlags } from "../../data/httpCalls";

// css
import { Autocomplete, TextField } from "@mui/material";
import { LaunchDarklyContext } from "../../context/LaunchDarklyContext";
import "../../css/App.css";
import "../../css/EnvironmentsStyle.css";
import ToggleRoSForm from "./forms/ToggleRoSForm";

export default function Environments() {
  const navigate = useNavigate();
  const {
    currentEnvironment,
    setCurrentFeatureFlag,
    currentFeatureFlagsList,
    setCurrentFeatureFlagsList,
    accessToken,
  } = useContext(LaunchDarklyContext);
  const [filteredFeatureFlags, setFilteredFeatureFlags] = useState([]);
  const [toggleRoSClicked, setToggleRoSClicked] = useState(false);

  // First load and when currentEnvironment changes
  useEffect(() => {
    if (currentEnvironment !== null && accessToken !== null) {
      getFeatureFlags(currentEnvironment.key);
    }
  }, [currentEnvironment, accessToken]);

  useEffect(() => {
    if (
      currentFeatureFlagsList !== null &&
      currentFeatureFlagsList.length > 0
    ) {
      const filteredData = filterFeatureFlagsInfo(
        currentFeatureFlagsList,
        currentEnvironment.key
      );
      setFilteredFeatureFlags(filteredData);
    }
    setToggleRoSClicked(false);
  }, [currentEnvironment, currentFeatureFlagsList]);

  const getFeatureFlags = async (environmentKey) => {
    try {
      setCurrentFeatureFlagsList([]); // delete old data
      const ffData = await fetchFeatureFlags(accessToken, environmentKey);
      setCurrentFeatureFlagsList(ffData.data);
    } catch (error) {
      console.error("Failed to fetch feature flag: ", error);
    }
  };

  function featureFlagClicked(flag) {
    setCurrentFeatureFlag(flag);
    navigate(`/${currentEnvironment.key}/${flag.key}`);
  }

  return (
    <div className="mainBody">
      <div className="mainHeader"> {currentEnvironment?.name}</div>
      {filteredFeatureFlags.length > 0 ? (
        <>
          <Button
            variant="info"
            onClick={() => setToggleRoSClicked(!toggleRoSClicked)}
          >
            {toggleRoSClicked ? "Cancel Toggling RoS ON" : "Toggle RoS ON"}
          </Button>
          {toggleRoSClicked && (
            <ToggleRoSForm setToggleRoSClicked={setToggleRoSClicked} />
          )}

          <Autocomplete
            disablePortal
            id="feature-flags-autocomplete"
            options={filteredFeatureFlags}
            getOptionLabel={(option) => option.name}
            sx={{ flex: 1, marginTop: "20px" }}
            onChange={(event, value) => {
              if (value) {
                featureFlagClicked(value);
              }
            }}
            renderInput={(params) => (
              <TextField {...params} label="Search Feature Flags" />
            )}
          />

          {filteredFeatureFlags.map((flag, index) => (
            <Card key={index} className="mainCard">
              <Card.Header>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    className="clickable"
                    onClick={() => featureFlagClicked(flag)}
                  >
                    {flag.name}
                  </div>
                  <div className="stackStatus">{flag.stackStatus}</div>
                </div>
              </Card.Header>
              <Card.Body>
                {flag.key}
                {flag.description !== "" && (
                  <footer className="blockquote-footer">
                    {flag.description}
                  </footer>
                )}
              </Card.Body>
            </Card>
          ))}
        </>
      ) : (
        <div className="loadingSpinner">
          <Spinner animation="border" role="status"></Spinner>
          <div> Please wait, we're getting feature flags... </div>
        </div>
      )}
    </div>
  );
}
