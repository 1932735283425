import { useContext, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { LaunchDarklyContext } from "../../../context/LaunchDarklyContext";
import { UpdateCurrentFeatureFlag } from "../../../data/featureFlagsHelpers";
import { addANewRule } from "../../../data/httpCalls";
import "./../../../css/Form.css";

export default function AddRuleForm({ setAddRuleClicked }) {
  const {
    currentEnvironment,
    currentFeatureFlag,
    setCurrentFeatureFlag,
    setCurrentFeatureFlagsList,
    accessToken,
  } = useContext(LaunchDarklyContext);
  const ruleTypes = ["LocationId", "GroupId"];
  const ruleStatuses = ["ON", "OFF"];
  const [description, setDescription] = useState("");
  const [ruleType, setRuleType] = useState(ruleTypes[0]);
  const [ruleStatus, setRuleStatus] = useState(ruleStatuses[0]);
  const [values, setValues] = useState([""]);
  const [beforeRuleId, setBeforeRuleId] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  const handleValueChange = (index, event) => {
    const newValues = [...values];
    newValues[index] = event.target.value;
    setValues(newValues);
  };

  const handleRemoveValue = (index) => {
    if (values.length > 1) {
      const newValues = values.filter((value, i) => i !== index);
      setValues(newValues);
    } else if (values.length === 1) {
      setValues([""]);
    }
  };

  const handleAddValue = () => {
    setValues([...values, ""]);
  };

  const newRuleSubmitted = async (event) => {
    event.preventDefault();
    const chosenStatus = ruleStatus === "ON" ? true : false;
    const variation = currentFeatureFlag.variations.find(
      (variation) => variation.value === chosenStatus
    );

    const newRuleInstruction = {
      kind: "addRule",
      beforeRuleId: beforeRuleId,
      description: description.trim(),
      variationId: variation._id,
      clauses: [
        {
          contextKind: "user",
          attribute: ruleType,
          negate: false,
          op: "in",
          values: values,
        },
      ],
    };

    try {
      const response = await addANewRule(
        accessToken,
        currentEnvironment.key,
        currentFeatureFlag.key,
        newRuleInstruction
      );

      // update
      await UpdateCurrentFeatureFlag(
        currentEnvironment,
        currentFeatureFlag,
        setCurrentFeatureFlag,
        setCurrentFeatureFlagsList,
        accessToken
      );
      handleClearForm();
      setAddRuleClicked(false);
    } catch (error) {
      setErrorMessage(error.message);
      console.error(`Adding a new rule failed: ${error}`);
    }
  };

  const handleClearForm = () => {
    setDescription("");
    setRuleType(ruleTypes[0]);
    setRuleStatus(ruleStatuses[0]);
    setValues([""]);
    setBeforeRuleId("");
    setErrorMessage(null);
  };

  return (
    <Container
      fluid
      className={`formContainer ${errorMessage ? "formContainerError" : ""}`}
    >
      <Form onSubmit={newRuleSubmitted}>
        {errorMessage && <div className="errorMessage">{errorMessage}</div>}
        <Form.Group as={Row} controlId="formDescription" className="row">
          <Form.Label column sm="2">
            Description
          </Form.Label>
          <Col sm="10">
            <Form.Control
              required
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="e.g. '[Your Name] ROS ON'"
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formType" className="row">
          <Form.Label column sm="2">
            Type
          </Form.Label>
          <Col sm="10">
            <Form.Control
              as="select"
              value={ruleType}
              onChange={(e) => setRuleType(e.target.value)}
            >
              {ruleTypes.map((type, index) => (
                <option value={type} key={index}>
                  {type}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formStatus" className="row">
          <Form.Label column sm="2">
            Status
          </Form.Label>
          <Col sm="10">
            <Form.Control
              as="select"
              value={ruleStatus}
              onChange={(e) => setRuleStatus(e.target.value)}
            >
              {ruleStatuses.map((status, index) => (
                <option value={status} key={index}>
                  {status}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formBeforeRuleID" className="row">
          <Form.Label column sm="2">
            Before Rule
          </Form.Label>
          <Col sm="10">
            <Form.Control
              as="select"
              value={beforeRuleId}
              onChange={(e) => setBeforeRuleId(e.target.value)}
            >
              {currentFeatureFlag?.rules.map((rule, index) => (
                <option value={rule.ruleId} key={index}>
                  {rule.description ? `${rule.description} - ` : ""}
                  {rule.ruleId}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formValues" className="row">
          <Form.Label column sm="2">
            Values
          </Form.Label>
          <Col sm="10">
            {values.map((value, index) => (
              <Row key={index} className="mb-2">
                <Col>
                  <Form.Control
                    required
                    type="text"
                    value={value}
                    onChange={(e) => handleValueChange(index, e)}
                    placeholder="(e.g '78e0aa2a-3329-41f0-beee-61f7ab978f86')"
                  />
                </Col>

                <Col xs="auto">
                  <Button
                    variant="danger"
                    onClick={() => handleRemoveValue(index)}
                  >
                    {"Remove"}
                  </Button>
                </Col>
              </Row>
            ))}
            <Button variant="primary" onClick={handleAddValue}>
              Add Value
            </Button>
          </Col>
        </Form.Group>

        <Row>
          <Col
            sm={{ span: 10, offset: 2 }}
            className="d-flex justify-content-end"
          >
            <Button
              variant="secondary"
              className="mr-2"
              onClick={handleClearForm}
            >
              {"Clear"}
            </Button>
            <Button variant="success" type="submit">
              {"Add New Rule"}
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}
