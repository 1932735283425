import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
    auth: {
        clientId: 'bcf6ee70-3073-4b14-9d54-a2e898a5455e',
        // tenant subdomain
        authority: 'https://login.microsoftonline.com/partech.onmicrosoft.com',
        // Points to window.location.origin. Must register this URI on Azure Portal/App Registration
        redirectUri: `${process.env.REACT_APP_LAUNCH_DARKLY_REDIRECT}`,
        // Indicates the page to navigate after logout
        postLogoutRedirectUri: '/',
        // If "true", will navigate back to the original request location before processing the auth code response
        navigateToLoginRequestUrl: false,
    },
    cache: {
        // Configures caches location: "sessionStorage" is more secure
        cacheLocation: 'sessionStorage',
        // Set this to "true" if having issue with IE11/Edge
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii){
                    return;
                }
                switch(level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            }
        }
    }
}

/**
 * Scopes added here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 */
export const loginRequest = {
    scopes: ['api://dfdb2515-6b9a-438e-9179-15e2286cb250/LD.ReadAndWrite', 'User.Read']
}