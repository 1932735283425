import { useContext, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Collapse,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { LaunchDarklyContext } from "../../../context/LaunchDarklyContext";
import { UpdateCurrentFeatureFlag } from "../../../data/featureFlagsHelpers";
import { getRoSFlags, toggleRoS } from "../../../data/httpCalls";
// css
import "../../../css/App.css";
import "./../../../css/Form.css";

export default function ToggleRoSForm({ setToggleRoSClicked }) {
  const {
    currentEnvironment,
    currentFeatureFlag,
    setCurrentFeatureFlag,
    setCurrentFeatureFlagsList,
    accessToken,
  } = useContext(LaunchDarklyContext);

  const ruleTypes = ["LocationId", "GroupId"];
  const ruleStatuses = ["ON"]; // no OFF for now
  const [description, setDescription] = useState("");
  const [ruleType, setRuleType] = useState(ruleTypes[0]);
  const [ruleStatus, setRuleStatus] = useState(ruleStatuses[0]);
  const [values, setValues] = useState([""]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [RoSListOpen, setRoSListOpen] = useState(false);
  const [loadingSpinner, setLoadingSpinner] = useState(false);

  // Result modal
  const [resultModalShow, setResultModalShow] = useState(false);
  const [ruleDescription, setRuleDescription] = useState("");
  const [successArray, setSuccessArray] = useState([]);
  const [failedArray, setFailedArray] = useState([]);

  // ROS Flags
  const [RoSFeatureFlags, setRoSFeatureFlags] = useState([]);

  useEffect(() => {
    fetchRoSFlags();
  }, []);

  /** Fetch a list of RoS flags */
  const fetchRoSFlags = async () => {
    try {
      const flags = await getRoSFlags(accessToken);
      setRoSFeatureFlags(flags);
    } catch (error) {
      setErrorMessage("Failed to load RoS flags");
    }
  };

  /** Update ID's values*/
  const handleValueChange = (index, event) => {
    const newValues = [...values];
    newValues[index] = event.target.value;
    setValues(newValues);
  };

  /** Remove ID fields. Clear the field when there's only 1 value field left.*/
  const handleRemoveValue = (index) => {
    if (values.length > 1) {
      const newValues = values.filter((_, i) => i !== index);
      setValues(newValues);
    } else if (values.length === 1) {
      setValues([""]);
    }
  };

  /** Add value field for IDs */
  const handleAddValue = () => {
    setValues([...values, ""]);
  };

  /**Call API to toggle RoS flags, update the success/fail messages along the way.
     Display the Result Modal at the end. */
  const toggleRoSSubmitted = async (event) => {
    event.preventDefault();

    const newToggleRoSRequest = {
      description: description.trim(),
      clauses: [
        {
          contextKind: "user",
          attribute: ruleType,
          negate: false,
          op: "in",
          values: values,
        },
      ],
    };

    try {
      setLoadingSpinner(true);
      const response = await toggleRoS(
        accessToken,
        currentEnvironment.key,
        newToggleRoSRequest,
        setSuccessMessage,
        setErrorMessage
      );
      setToggleRoSResultModal(description.trim(), response);
      handleClearForm();
    } catch (error) {
      setErrorMessage(error.message);
      console.error(`Adding a new rule failed: ${error}`);
    }
  };

  /** Only update the current feature flag once modal is closed
    to avoid page reload making modal disappear.*/
  const handleCloseModal = async () => {
    setResultModalShow(false);
    await UpdateCurrentFeatureFlag(
      currentEnvironment,
      currentFeatureFlag,
      setCurrentFeatureFlag,
      setCurrentFeatureFlagsList,
      accessToken
    );
    setToggleRoSClicked(false);
  };

  /** Filter the success and fail toggle result */
  const setToggleRoSResultModal = (ruleDescription, result) => {
    let successArray = [];
    let failedArray = [];
    result.forEach((result) => {
      if (result.status === 0) {
        successArray.push(result.message);
      } else {
        failedArray.push(result.message);
      }
    });

    setResultModalShow(true);
    setRuleDescription(ruleDescription);
    setSuccessArray(successArray);
    setFailedArray(failedArray);
  };

  const handleClearForm = () => {
    setDescription("");
    setRuleType(ruleTypes[0]);
    setRuleStatus(ruleStatuses[0]);
    setValues([""]);
    setErrorMessage(null);
    setSuccessMessage(null);
    setRoSListOpen(false);
    setLoadingSpinner(false);
  };

  return (
    <Container
      fluid
      className={`formContainer ${errorMessage ? "formContainerError" : ""}`}
    >
      <Form onSubmit={toggleRoSSubmitted}>
        {/* List of ROS Flags */}
        <>
          <Alert variant="warning">
            <div className="RoSNote">
              {"NOTE: Toggling RoS ON will configure "}
              <Alert.Link onClick={() => setRoSListOpen(!RoSListOpen)}>
                {RoSFeatureFlags?.length} RoS-related Feature Flags
              </Alert.Link>
            </div>
            <Collapse in={RoSListOpen}>
              <div id="feature-flags-collapse-text">
                <ul>
                  {RoSFeatureFlags?.map((flag, index) => (
                    <li key={index}>
                      {flag.key} - {flag.status}
                    </li>
                  ))}
                </ul>
              </div>
            </Collapse>
          </Alert>
        </>

        {/* Loading Spinner */}
        {loadingSpinner && (
          <div className="loadingSpinner">
            <Spinner animation="border" role="status" variant="info"></Spinner>
            <div className="spinnerText">
              {"Please wait, we're toggling RoS Feature Flags..."}
            </div>
          </div>
        )}

        {/* Error Message */}
        {errorMessage && (
          <Alert variant="danger">
            {errorMessage.split("\n").map((msg, index) => (
              <div key={index}>{msg}</div>
            ))}
          </Alert>
        )}

        {/* Success Message */}
        {successMessage && (
          <Alert variant="success">
            {successMessage.split("\n").map((msg, index) => (
              <div key={index}>{msg}</div>
            ))}
          </Alert>
        )}

        {/* The Main Form */}
        <Form.Group as={Row} controlId="formDescription" className="row">
          <Form.Label column sm="2">
            Description
          </Form.Label>
          <Col sm="10">
            <Form.Control
              required
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="e.g. '[Your Name] ROS ON'"
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formType" className="row">
          <Form.Label column sm="2">
            Type
          </Form.Label>
          <Col sm="10">
            <Form.Control
              as="select"
              value={ruleType}
              onChange={(e) => setRuleType(e.target.value)}
            >
              {ruleTypes.map((type, index) => (
                <option value={type} key={index}>
                  {type}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formStatus" className="row">
          <Form.Label column sm="2">
            Status
          </Form.Label>
          <Col sm="10">
            <Form.Control
              as="select"
              value={ruleStatus}
              onChange={(e) => setRuleStatus(e.target.value)}
            >
              {ruleStatuses.map((status, index) => (
                <option value={status} key={index}>
                  {status}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formValues" className="row">
          <Form.Label column sm="2">
            Values
          </Form.Label>
          <Col sm="10">
            {values.map((value, index) => (
              <Row key={index} className="mb-2">
                <Col>
                  <Form.Control
                    required
                    type="text"
                    value={value}
                    onChange={(e) => handleValueChange(index, e)}
                    placeholder="(e.g '78e0aa2a-3329-41f0-beee-61f7ab978f86')"
                  />
                </Col>

                <Col xs="auto">
                  <Button
                    variant="danger"
                    onClick={() => handleRemoveValue(index)}
                  >
                    {"Remove"}
                  </Button>
                </Col>
              </Row>
            ))}
            <Button variant="primary" onClick={handleAddValue}>
              Add Value
            </Button>
          </Col>
        </Form.Group>

        <Row>
          <Col
            sm={{ span: 10, offset: 2 }}
            className="d-flex justify-content-end"
          >
            <Button
              variant="secondary"
              className="mr-2"
              onClick={handleClearForm}
            >
              {"Clear"}
            </Button>
            <Button variant="success" type="submit">
              {"Toggle RoS"}
            </Button>
          </Col>
        </Row>
      </Form>

      {/* Display the result */}
      <Modal
        size="lg"
        className="custom-modal"
        show={resultModalShow}
        onHide={() => handleCloseModal()}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Toggle RoS Result for rule: {ruleDescription}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {successArray.length > 0 && (
            <Alert variant="success">
              {successArray.map((message, index) => (
                <div key={index}>{message}</div>
              ))}
            </Alert>
          )}
          {failedArray.length > 0 && (
            <Alert variant="danger">
              {failedArray.map((message, index) => (
                <div key={index}>{message}</div>
              ))}
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => handleCloseModal()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
