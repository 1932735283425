import { fetchFeatureFlags } from "./httpCalls";

// Convert raw information into necessary info: name, key, description, stack status, rules (ruleStatus, clauses)
export const filterFeatureFlagsInfo = (featureFlagsData, environmentKey) => {
  let filteredObjects = [];

  featureFlagsData.forEach((flag) => {
    const environment = flag.environments[environmentKey];
    if (!environment) {
      return [];
    }

    let { fallthrough, rules = [] } = environment;
    let variations = flag.variations;

    // Stack status
    const isOn = environment.on;
    const stackStatusIndex = isOn ? fallthrough.variation : environment.offVariation;
    const stackStatus =
      variations[stackStatusIndex].value === true ? "ON" : "OFF";

    // rules
    const filteredRules = rules.map((rule) => {
      const ruleId = rule._id;
      const ruleStatus =
        variations[rule.variation].value === true ? "ON" : "OFF";
      const clauses = rule.clauses.map((clause) => ({
        attribute: clause.attribute,
        values: clause.values,
      }));
      const description = rule.description;
      return { ruleId, ruleStatus, clauses, description };
    });

    //Construct the object
    const filteredFlag = {
      name: flag.name,
      key: flag.key,
      description: flag.description,
      stackStatus: stackStatus,
      rules: filteredRules,
      variations: variations,
    };

    filteredObjects.push(filteredFlag);
  });
  return filteredObjects;
};

// When any current feature flag changes (add/remove rules, etc.),
// it's necessary to update the global states: currentFeatureFlag, currentFeatureFlagList
export const UpdateCurrentFeatureFlag = async (
  currentEnvironment,
  currentFeatureFlag,
  setCurrentFeatureFlag,
  setCurrentFeatureFlagsList,
  accessToken
) => {
  try {
    // 1. Refetching all FFs for current environment
    const ffData = await fetchFeatureFlags(accessToken, currentEnvironment.key);

    // 2. Update currentFFList
    setCurrentFeatureFlagsList(ffData.data);

    // 3. Update currentFF
    if (currentFeatureFlag !== null){
      const filteredData = filterFeatureFlagsInfo(
        ffData.data,
        currentEnvironment.key
      );
      const updatedCurrentFF = filteredData.find(
        (currentFF) => currentFeatureFlag.key === currentFF.key
      );
      setCurrentFeatureFlag(updatedCurrentFF);
    }
  } catch (error) {
    console.error(error);
  }
};
