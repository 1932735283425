import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import Environments from "./components/Environments/Environments";
import FeatureFlags from "./components/FeatureFlags/FeatureFlags";
import { LaunchDarklyProvider } from "./context/LaunchDarklyContext";
import NotFound from "./routes/NotFound";
import PrivateRoute from "./routes/PrivateRoute";

export default function Routing() {
  return (
    <LaunchDarklyProvider>
      <BrowserRouter>
        <Routes>
          {/* Private Routes */}
          <Route element={<PrivateRoute />}>
            <Route path="/" element={<App />}>
              <Route path="/:environmentKey" element={<Environments />} />
              <Route
                path="/:environmentKey/:featureFlagKey"
                element={<FeatureFlags />}
              />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </LaunchDarklyProvider>
  );
}
